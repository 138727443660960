// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.webp";
import ServicesBlocks from "./sections/services";
import RenderHelmet from "components/helmet/helmet";
import metaData from "components/helmet/meta-data/meta-data";

function Presentation() {
  return (
    <>
      <RenderHelmet metaData={metaData.homePage} />
      <DefaultNavbar routes={routes} sticky />
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <MKBox
          minHeight="75vh"
          width="100%"
          sx={{
            display: "grid",
            placeItems: "center",
            background:
              "linear-gradient(90deg,rgb(0,0,0) 0%,rgba(0,0,0,.8) 0%,rgba(0,0,0,.4) 100%)",
          }}
        >
          <Container>
            <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Hexobyte
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                Welcome to Hexobyte – Where Innovation Meets Precision. Founded by seasoned
                developers driven by a shared passion for crafting impeccable software solutions,
                Hexobyte is your gateway to transformative digital experiences.
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      </div>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <Counters /> */}
        {/* <Information /> */}
        {/* <DesignBlocks /> */}
        <ServicesBlocks />
        {/* <Pages /> */}
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Download />
        <MKBox pt={2} pb={6}>
          <Container>
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Grid item xs={8}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank You for Choosing Us!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  At Hexobyte, we&apos;re driven by our dedication to offer the finest and most
                  reliable web products in the industry. With years of expertise and a passion for
                  innovation, we&apos;re proud to present custom solutions that are not only
                  effective but also consistent with your unique business needs.
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
