import React from "react";
import styled from "@emotion/styled";
import logo from "../../assets/images/logos/LOGO.png";
import { Link } from "@mui/material";

const StyledLogo = styled.img`
  width: 30px;
  height: 28px;
  border-radius: 50%;
  padding: 2px;
  background: white;
`;

export const LogoLink = () => {
  return (
    // <Tooltip title={"Hexobyte"} placement="right" TransitionComponent={Zoom}>
    <Link variant="h6" href={"/"} underline="none" color="inherit" noWrap>
      <StyledLogo src={logo} width={"100%"} height={"100%"} />
    </Link>
    // </Tooltip>
  );
};
