/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// const imagesPrefix =
//   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

export default {
  webDevelopment: {
    title: "Our Services",
    description:
      "Explore our comprehensive web development services covering frontend and backend expertise, cloud support, responsive design, cross-browser compatibility, performance optimization, and MVP development, ensuring your digital presence is dynamic, efficient, and tailored to your needs.",
    items: [
      {
        title: "User-Centric Experiences",
        variant: "gradient",
        color: "dark",
        icon: "apps",
        description:
          "Utilizing our advanced front-end development expertise, we specialize in transforming your product into a user-centric masterpiece. With our front-end tech solutions, you'll enjoy improved usability, seamless navigation, and visually captivating interfaces, all contributing to an exceptional user experience.",
      },
      {
        title: "Robust Business logic",
        variant: "gradient",
        color: "info",
        icon: "apps",
        description:
          "Employing cutting-edge technologies, our expert team crafts a robust and scalable back end, bolstering performance, security, and data management. By optimizing databases and developing robust APIs, we facilitate seamless integration, guaranteeing a reliable and dynamic user experience.",
      },
      {
        title: "Seamless Availability & Maintenance",
        variant: "gradient",
        color: "info",
        icon: "apps",
        description:
          "Harnessing our robust cloud solutions, we provide unmatched scalability and flexibility, enabling seamless adaptation to evolving demands for your product. Unlock unparalleled growth and success as we optimize your product's infrastructure with our exceptional cloud services.",
      },
      {
        title: "Universal User Base Support",
        description:
          "Leveraging our proficiency in front-end frameworks, we construct responsive websites that effortlessly adjust to various screen sizes, ensuring an exceptional user experience across desktop devices.",

        variant: "gradient",
        color: "dark",
        icon: "apps",
      },
      {
        title: "Ensure fail safe implementation",
        description:
          "Using a combination of automated testing tools and manual testing processes, our quality engineers rigorously test your software to ensure it meets the highest standards of quality and performance.",

        variant: "gradient",
        color: "dark",
        icon: "apps",
      },
      {
        title: "Lighting Fast load times",
        description:
          "Utilizing industry-leading practices, our team enhances your website's performance, guaranteeing swift load times and seamless navigation to elevate user satisfaction.",

        variant: "gradient",
        color: "info",
        icon: "apps",
      },
      {
        title: "Accelerated Idea-to-Reality Pathways",
        description:
          "Accelerate your product launch. We expedite the realization of your product vision, enabling rapid validation of ideas, collection of user feedback, and efficient iteration processes.",

        variant: "gradient",
        color: "info",
        icon: "apps",
      },
    ],
  },
  mobileDevelopment: {
    title: "Our Services",
    description:
      "From harnessing Apple's ecosystem to mastering Android intricacies, crafting dynamic React Native and Flutter apps, our mobile development services ensure top-tier performance, seamless user experiences, and maximum reach across platforms.",
    items: [
      {
        variant: "gradient",
        color: "dark",
        icon: "apple",
        title: "iOS App Development",
        description:
          "Leveraging Apple's ecosystem, our adept developers create apps that offer unparalleled performance and delight for your users. Adhering to stringent design standards and employing cutting-edge technologies, we ensure your app shines in the competitive App Store, leaving a lasting mark on your audience.",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "android",
        title: "Android App Development",
        description:
          "Understanding the intricacies of the Android platform, we optimize your app to deliver seamless functionality and user experiences. Our Android apps seamlessly adapt to diverse screen sizes and device specifications, enhancing user engagement and satisfaction across smartphones, tablets, and beyond.",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "apps",
        title: "React Native App Development",
        description:
          "Committed to realizing your product vision, we specialize in React Native app development. Crafting dynamic and responsive mobile applications, we ensure a consistent user experience on both iOS and Android devices, accelerating development, reducing costs, and enabling faster reach to your target audience with higher returns on investment.",
      },
      {
        title: "Flutter App Development",
        description:
          "With our Flutter app development services, you benefit from a unified codebase that seamlessly functions across iOS and Android devices. Leveraging Flutter's expressive UI and reactive design, we create visually stunning, feature-rich apps with consistent performance and user experience, saving you time and resources while maximizing reach and impact.",

        variant: "gradient",
        color: "dark",
        icon: "apps",
      },
    ],
  },
  uxDesign: {
    title: "Our Services",
    description:
      "Crafting intuitive and engaging user experiences through thoughtful design solutions, our UX design services prioritize user satisfaction and interaction efficiency, ensuring your digital products resonate with your audience and drive lasting engagement.",
    items: [
      {
        variant: "gradient",
        color: "dark",
        icon: "apps",
        title: "User Experience Design",
        description:
          "Prioritizing user experience, we specialize in crafting intuitive interfaces for your digital products. By leveraging the latest design trends, we ensure your products engage users effectively, fostering positive interactions and building brand loyalty.",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "apps",
        title: "UX Research and Testing",
        description:
          "Through comprehensive UX research and testing, we gain valuable insights into your users' preferences and behaviors. Tailoring design and functionality to meet their needs, we ensure your products resonate with your target audience effectively.",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "apps",
        title: "Interactive Prototyping",
        description:
          "Visualizing and testing the user journey through interactive prototypes, we refine designs based on user feedback, ensuring the final product aligns perfectly with user expectations.",
      },
      {
        title: "UX Writing",
        description:
          "Focused on effective communication, we emphasize human-centric content delivery to provide seamless user experiences that convey the precise message customers need.",

        variant: "gradient",
        color: "dark",
        icon: "apps",
      },
      {
        title: "Interaction Design",
        description:
          "Recognizing the importance of seamless interactions, we meticulously design your digital platform to create engaging and memorable journeys for your customers, combining attention to detail with a deep understanding of user psychology.",

        variant: "gradient",
        color: "dark",
        icon: "apps",
      },
    ],
  },
  devops: {
    title: "Our Services",
    description:
      "Efficiently streamline development, optimize cloud infrastructure, and automate deployment with our DevOps expertise. From tailored migration strategies to centralized settings and simplified app management, we ensure seamless transitions and enhanced control for your projects.",
    items: [
      {
        variant: "gradient",
        color: "dark",
        icon: "flag",
        title: "Application Modernization",
        description:
          "Staffing the right people and utilizing necessary tools and infrastructure, implementing tailored processes and best practices, generating project reports, and ensuring timely deployment of deliverables.",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "precision_manufacturing",
        title: "CloudOps",
        description:
          "Crafting tailored migration strategies, leveraging expertise in cloud application migration, optimizing cloud infrastructure, monitoring performance, seamlessly integrating with AWS, Azure & GCP, and ensuring universal applicability across platforms.",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "apps",
        title: "ContainerOps",
        description:
          "Efficiently manage and orchestrate containers, streamline deployment, scaling, and security, leverage Kubernetes and automation for enhanced efficiency, improve portability, collaboration, and version control, and enhance resource utilization and scalability.",
      },
      {
        title: "DevOps",
        description:
          "Establish and optimize CI/CD pipelines, utilize tools such as Jenkins, GitLab CI, and CircleCI, automate building, testing, and deployment processes, enhance software release quality and speed, and eliminate manual, time-consuming tasks.",

        variant: "gradient",
        color: "dark",
        icon: "apps",
      },
      {
        title: "Security and Compliance",
        description:
          "Crafting tailored migration strategies for smooth transitions, centralizing settings for easy control, providing a comprehensive dashboard for visibility and control, and simplifying app management to save time and effort.",

        variant: "gradient",
        color: "dark",
        icon: "apps",
      },
    ],
  },
  qualityEngineering: {
    title: "Our Services",
    description:
      "Ensuring software excellence through rigorous testing, automation, performance optimization, compatibility checks, security assessments, and user experience enhancements.",
    items: [
      {
        variant: "gradient",
        color: "dark",
        icon: "settings_applications",
        title: "Functional Testing",
        description:
          "Through meticulous functional testing, we ensure your software applications, websites, or products meet all specified requirements, identifying defects and ensuring seamless performance across core functionalities, user interactions, and system integrations.",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "precision_manufacturing",
        title: "Automation Testing",
        description:
          "Specializing in test automation solutions, we accelerate product development cycles, enhancing efficiency and reliability through accelerated testing cycles and consistent results.",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "insert_chart",
        title: "Performance Testing",
        description:
          "Prioritizing performance, we assess responsiveness, scalability, and stability under varying load conditions, identifying and addressing bottlenecks to ensure optimal performance, even during peak usage.",
      },
      {
        title: "Compatibility Testing",
        description:
          "Assessing compatibility across platforms, devices, and operating systems, we ensure consistent user experiences and maximum accessibility, testing your offerings on various configurations to meet the needs of your diverse audience.",

        variant: "gradient",
        color: "dark",
        icon: "display_settings",
      },
      {
        title: "Security Testing",
        description:
          "In response to increasing cybersecurity threats, our security testing services identify vulnerabilities and safeguard your applications and systems, conducting comprehensive assessments to protect sensitive data confidentiality, integrity, and availability, mitigating potential risks.",

        variant: "gradient",
        color: "dark",
        icon: "security",
      },
      {
        title: "Usability Testing",
        description:
          "Recognizing the importance of user experience, our usability testing services evaluate ease of use, intuitiveness, and overall satisfaction, gathering feedback and insights to enhance user experiences and boost customer engagement.",

        variant: "gradient",
        color: "info",
        icon: "devices",
      },
    ],
  },
  dataAndAi: {
    headerTitle: "Data-driven Innovation: Transforming Insights into Action",
    headerDescription: `Unlock the full potential of your data with our comprehensive Data and AI services. From harnessing advanced analytics to deploying cutting-edge AI models, we empower businesses to uncover valuable insights, drive innovation, and stay ahead in today's competitive landscape. Our expert team specializes in developing tailored solutions that leverage the latest technologies to address your unique business challenges and unlock new opportunities for growth. Whether you're looking to optimize operations, enhance customer experiences, or drive strategic decision-making, we're here to help you harness the power of data and AI to achieve your goals.`,
  },
  architecture: {
    title: "Our Services",
    description:
      "Tailored software architecture solutions crafted to meet your project's unique needs. We prioritize your business objectives and customer value, specializing in microservices and serverless architectures to ensure optimal project alignment and success.",
    headerTitle: "Software Architecture Services",
    headerDescription: `The success of your software is based on three pillars. They are flexibility,
    responsiveness, and reliability. And you can easily achieve them with competent software
    architecture building. We are here to lay the foundation for the future growth of your
    product.
    We offer a full range of software architecture consulting services covering design
    architecture solutions from scratch, as well as assessments and audits of your current
    architecture`,

    items: [
      {
        variant: "gradient",
        color: "dark",
        icon: "flag",
        title: "Architecture migration",
        description:
          "At Hexobyte, we believe in revitalizing software solutions through modernization. Our migration services seamlessly transition your outdated monolith architecture to agile microservices and serverless models, ensuring your technology evolves with your business needs. ",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "precision_manufacturing",
        title: "Architecture audit",
        description:
          "If you're seeking to enhance your software solution, Hexobyte is here to assist you. Our software architecture consultants offer comprehensive architecture audits and assessments to identify and address system vulnerabilities. From architecture debt evaluation to technical debt assessment and codebase vulnerability evaluation, our services ensure your software architecture is robust and optimized for success..",
      },
      {
        variant: "gradient",
        color: "info",
        icon: "apps",
        title: "Architecture design",
        description:
          "At Hexobyte, we specialize in crafting bespoke software architecture designs tailored to your unique projects. Our approach is rooted in understanding your business objectives and the value your product brings to customers. With expertise in microservices and serverless architectures, we delve deep into your project requirements and vision to design the most suitable architectural solutions that drive success.",
      },
    ],
  },
};
