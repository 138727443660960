import Helmet from "react-helmet";
import { PropTypes } from "prop-types";

export default function RenderHelmet({ metaData }) {
  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
    </Helmet>
  );
}
RenderHelmet.propTypes = {
  metaData: PropTypes.object.isRequired,
};
