import NodeJsImage from "assets/images/technology-logos/nodejs.png";
import NextJsImage from "assets/images/technology-logos/nextjs.png";
import ReactJsImage from "assets/images/technology-logos/reactJs.png";
import AngularImage from "assets/images/technology-logos/angular.png";
import VueJsImage from "assets/images/technology-logos/vuejs.png";
import PythonImage from "assets/images/technology-logos/python.png";
import GoLangImage from "assets/images/technology-logos/golang.png";
import AwsImage from "assets/images/technology-logos/aws.png";
import GcpImage from "assets/images/technology-logos/gcp.png";
import AzureImage from "assets/images/technology-logos/azure.png";
import FireBaseImage from "assets/images/technology-logos/firebase.png";
import iosImage from "assets/images/technology-logos/ios.png";
import AndroidImage from "assets/images/technology-logos/android.png";
import FlutterImage from "assets/images/technology-logos/flutter.png";
import DotNetImage from "assets/images/technology-logos/dotnet.png";
import JavaImage from "assets/images/technology-logos/java.png";
import MlImage from "assets/images/technology-logos/ml.png";
import AiImage from "assets/images/technology-logos/ai.png";
import MaterialImage from "assets/images/technology-logos/material.png";

export default [
  {
    tooltipTitle: "NodeJs: Power your backend with Node.js for scalable server-side applications.",
    // href: "https://nodejs.org/en",
    imageUrl: NodeJsImage,
  },
  {
    tooltipTitle: "NextJs: Craft blazing-fast web experiences with Next.js and React.",
    // href: "https://nextjs.org/",
    imageUrl: NextJsImage,
  },
  {
    tooltipTitle:
      "ReactJs: Build dynamic user interfaces with ReactJS for unparalleled performance.",
    // href: "https://react.dev/",
    imageUrl: ReactJsImage,
  },
  {
    tooltipTitle: "Angular: Scale your applications with Angular's comprehensive framework.",
    // href: "https://angular.io/",
    imageUrl: AngularImage,
  },
  {
    tooltipTitle: "VueJs: Create elegant and responsive interfaces with Vue.js.",
    // href: "https://vuejs.org/",
    imageUrl: VueJsImage,
  },
  {
    tooltipTitle: "Material UI: Craft beautiful and responsive user interfaces with Material-UI",
    // href: "https://mui.com/material-ui/",
    imageUrl: MaterialImage,
  },
  {
    tooltipTitle:
      "Python: Leverage Python's versatility for web development, data science, and automation.",
    // href: "https://www.python.org/",
    imageUrl: PythonImage,
  },
  {
    tooltipTitle: "GO: Drive efficiency and simplicity with GO for high-performance applications.",
    // href: "https://go.dev/",
    imageUrl: GoLangImage,
  },
  {
    tooltipTitle: "AWS: Scale and innovate with AWS, the leading cloud platform.",
    // href: "https://aws.amazon.com/",
    imageUrl: AwsImage,
  },
  {
    tooltipTitle: "GCP: Innovate with Google Cloud Platform's cutting-edge services.",
    // href: "https://cloud.google.com/",
    imageUrl: GcpImage,
  },
  {
    tooltipTitle: "Azure: Grow your business with Microsoft Azure's comprehensive cloud solutions.",
    // href: "https://azure.microsoft.com/en-in/",
    imageUrl: AzureImage,
  },
  {
    tooltipTitle: "Firebase: Rapidly develop and scale apps with Firebase's intuitive platform.",
    // href: "https://firebase.google.com/",
    imageUrl: FireBaseImage,
  },
  {
    tooltipTitle: "ReactNative: Build native mobile apps for iOS and Android with React Native.",
    // href: "https://reactnative.dev/",
    imageUrl: ReactJsImage,
  },
  {
    tooltipTitle: "iOS: Deliver seamless experiences to iOS users with our expertise.",
    // href: "https://developer.apple.com/",
    imageUrl: iosImage,
  },
  {
    tooltipTitle: "Android: Engage millions of users with our Android development services.",
    // href: "https://developer.android.com/",
    imageUrl: AndroidImage,
  },
  {
    tooltipTitle:
      "Flutter: Create stunning cross-platform apps with Flutter's expressive UI toolkit.",
    // href: "https://flutter.dev/",
    imageUrl: FlutterImage,
  },
  {
    tooltipTitle: ".Net: Innovate with .NET for modern web, desktop, and cloud applications.",
    // href: "https://dotnet.microsoft.com/en-us/",
    imageUrl: DotNetImage,
  },
  {
    tooltipTitle: "Java: Trust Java for secure and high-performance enterprise applications.",
    // href: "https://www.java.com/en/",
    imageUrl: JavaImage,
  },
  {
    tooltipTitle: "ML: Drive innovation and automation with our machine learning expertise.",
    // href: "https://www.tensorflow.org/",
    imageUrl: MlImage,
  },
  {
    tooltipTitle:
      "GenerativeAI: Explore creative possibilities with Generative AI for art and music.",
    // href: "https://openai.com/",
    imageUrl: AiImage,
  },
  {
    tooltipTitle:
      "BootStrap: Craft responsive and compelling interfaces with the most popular CSS framework in the world.",
    // href: "https://hexobyte.com",
    imageUrl:
      "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/bootstrap5.jpg",
  },
  {
    tooltipTitle:
      "Tailwind: Implement custom designs swiftly with a utility-first CSS framework for rapid UI development.",
    // href: "https://hexobyte.com",
    imageUrl:
      "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/icon-tailwind.jpg",
  },
];
