// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, addresses, menus, copyright, services } = content;

  return (
    <MKBox component="footer" sx={{ mb: 8 }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} sx={{ width: "25%" }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" />
                <MKTypography variant="h6"> {brand.name} </MKTypography>
                <MKTypography>{copyright}</MKTypography>
              </Link>
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ width: "25%" }}>
            {services.map(({ name: title, items }) => (
              <>
                <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mb={1}
                >
                  {title}
                </MKTypography>
                <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                  {items.map(({ name, route, href }) => (
                    <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                      {href ? (
                        <MKTypography
                          component="a"
                          href={href}
                          target="_blank"
                          rel="noreferrer"
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      ) : (
                        <MKTypography
                          component={Link}
                          to={route}
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      )}
                    </MKBox>
                  ))}
                </MKBox>
              </>
            ))}
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ width: "25%" }}>
            {addresses.map(({ title, address }) => (
              <>
                <MKTypography
                  key={title}
                  display="block"
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mb={1}
                >
                  {title}
                </MKTypography>
                <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                  <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                    <MKTypography
                      target="_blank"
                      rel="noreferrer"
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                    >
                      {address}
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </>
            ))}
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ width: "25%" }}>
            {menus.map(({ name: title, items }) => (
              <>
                <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mb={1}
                >
                  {title}
                </MKTypography>
                <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                  {items.map(({ name, route, href }) => (
                    <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                      {href ? (
                        <MKTypography
                          component="a"
                          href={href}
                          target="_blank"
                          rel="noreferrer"
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      ) : (
                        <MKTypography
                          component={Link}
                          to={route}
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      )}
                    </MKBox>
                  ))}
                </MKBox>
              </>
            ))}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
