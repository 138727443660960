// @mui material components
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

const MKSelectRoot = styled(Select)(({ theme, ownerState }) => {
  const { palette } = theme;
  const { error, success, disabled } = ownerState;

  const { grey, transparent, error: colorError, success: colorSuccess } = palette;

  // Common styles for error and success states, focusing on the outline and label colors
  const focusedStateStyles = (color) => ({
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline, &:after": {
        borderColor: color.main,
      },
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: color.main,
    },
  });

  // Styles for the select component when in error state
  const errorStyles = {
    ...focusedStateStyles(colorError),
    // Additional error-specific styles can go here
  };

  // Styles for the select component when in success state
  const successStyles = {
    ...focusedStateStyles(colorSuccess),
    // Additional success-specific styles can go here
  };

  return {
    ".MuiSelect-select": {
      paddingTop: "0px", // Adjust top padding
      paddingBottom: "0px",
    },
    backgroundColor: disabled ? `${grey[200]} !important` : transparent.main,
    pointerEvents: disabled ? "none" : "auto",
    ...(error && errorStyles),
    ...(success && successStyles),
    padding: "0",
  };
});

export default MKSelectRoot;
