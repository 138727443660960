/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// const imagesPrefix =
//   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

export default [
  {
    title: "Our Services",
    description:
      "Crafting tailored digital solutions with expertise in web and mobile development, UX design, DevOps, quality engineering, data analytics, AI, and strategic software consultancy.",
    items: [
      {
        route: "/our-services/web-development",
        variant: "gradient",
        color: "dark",
        icon: "flag",
        title: "Web Development",
        description:
          "Harnessing the power of technologies like React, Angular, Java, Python, and Node, we create dynamic and interactive web applications that engage users and drive business growth.",
        action: {
          type: "external",
          route: "/our-services/web-development",
          label: "Read more",
        },
      },
      {
        route: "/our-services/mobile-development",
        variant: "gradient",
        color: "info",
        icon: "precision_manufacturing",
        title: "Mobile Development",
        description:
          "From Android to iOS, and leveraging frameworks like Flutter and React Native, our team specializes in developing high-performance mobile apps that deliver seamless user experiences across platforms.",
        action: {
          type: "external",
          route: "/our-services/mobile-development",
          label: "Read more",
        },
      },
      {
        route: "/our-services/ux-design",
        variant: "gradient",
        color: "info",
        icon: "apps",
        title: "Ux Design",
        description:
          "Our UX designers utilize the latest tools and techniques to craft intuitive and visually stunning interfaces that elevate your brand and delight your users.",
        action: {
          type: "external",
          route: "/our-services/ux-design",
          label: "Read more",
        },
      },
      {
        title: "DevOps Services",
        description:
          "With expertise in cloud platforms such as AWS, GCP, and Azure, combined with proficiency in technologies like Docker and Kubernetes, we ensure your applications are reliable, scalable, and always available.",
        route: "/our-services/devops",
        variant: "gradient",
        color: "dark",
        icon: "apps",
        action: {
          type: "external",
          route: "/our-services/devops",
          label: "Read more",
        },
      },
      {
        title: "Quality Engineering",
        description:
          "Using a combination of automated testing tools and manual testing processes, our quality engineers rigorously test your software to ensure it meets the highest standards of quality and performance.",
        route: "/our-services/quality-engineering",
        variant: "gradient",
        color: "dark",
        icon: "apps",
        action: {
          type: "external",
          route: "/our-services/quality-engineering",
          label: "Read more",
        },
      },
      {
        title: "Data & Artificial Intelligence",
        description:
          "Unlock the power of data-driven insights and intelligent solutions with our comprehensive Data and Artificial Intelligence services. Harnessing cutting-edge technologies, we empower businesses to make informed decisions, drive innovation, and stay ahead in today's data-driven world.",
        route: "/our-services/data-ai",
        variant: "gradient",
        color: "info",
        icon: "apps",
        action: {
          type: "external",
          route: "/our-services/data-ai",
          label: "Read more",
        },
      },
      {
        title: "Architecture Services",
        description:
          "We offer a full range of software architecture consulting services covering design architecture solutions from scratch, as well as assessments and audits of your current architecture.",
        route: "/our-services/architecture",
        variant: "gradient",
        color: "info",
        icon: "apps",
        action: {
          type: "external",
          route: "/our-services/architecture",
          label: "Read more",
        },
      },
      {
        title: "Software Consultancy",
        description:
          "Navigate the complexities of software development with confidence through our expert Software Consultancy Services. From strategic planning to implementation, we offer tailored guidance and solutions to help your business thrive in the ever-evolving digital landscape.",
        variant: "gradient",
        color: "dark",
        icon: "apps",
      },
    ],
  },
];
