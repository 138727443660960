import { React } from "react";

import PropTypes from "prop-types";
import MKSelectRoot from "components/MKSelect/MKSelectRoot";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
const MKSelectBox = ({ label, children, error, success, disabled, ...props }) => {
  return (
    <FormControl
      variant="standard"
      sx={{ my: 2, minWidth: 120 }}
      fullWidth
      error={error}
      disabled={disabled}
    >
      {label && <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>}
      <MKSelectRoot
        labelId="demo-simple-select-standard-label"
        {...props}
        ownerState={{ error, success, disabled }}
      >
        {children}
      </MKSelectRoot>
    </FormControl>
  );
};
MKSelectBox.defaultProps = {
  label: "label",
  children: false,
  error: false,
  success: false,
  disabled: false,
};
MKSelectBox.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MKSelectBox;
