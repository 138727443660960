export default {
  homePage: {
    title: "Hexobyte",
    description:
      "Welcome to Hexobyte – Where Innovation Meets Precision. Founded by seasoned developers driven by a shared passion for crafting impeccable software solutions, Hexobyte is your gateway to transformative digital experiences.",
  },
  aboutUs: {
    title: "About us | Hexobyte",
    description: `At Hexobyte, we're committed to empowering businesses with innovative solutions,
      exceptional quality, and unwavering customer satisfaction. With a blend of
      cutting-edge technologies and strategic expertise, we turn your digital aspirations
      into reality. Join us as we navigate the ever-evolving digital landscape together,
      driving your success every step of the way.`,
  },
  contactUs: {
    title: "Contact us | Hexobyte",
    description: `Get in touch with us to learn more about our products and services. Whether you have questions, feedback, or inquiries, our Hexobyte team is here to assist you. Reach out via phone, email, or through the contact form on this page. We look forward to hearing from you!`,
  },

  ourServices: {
    webDevelopment: {
      title: "Web Development | Hexobyte",
      description: `Revolutionize Your Online Presence with Expert Web Development at Hexobyte! Embark on a transformative digital journey with our expertly crafted web development solutions, designed to revolutionize your online presence and captivate your audience.`,
    },
    mobileDevelopment: {
      title: "Mobile Development | Hexobyte",
      description: `Transforming Visions into Reality with Exceptional Mobile Development at Hexobyte! Embark on a dynamic digital journey with our personalized mobile development solutions, as we ignite your vision and craft a powerful pathway for your product.`,
    },
    uxDesign: {
      title: "Ux Design | Hexobyte",
      description: `Enhancing user experiences through tailored UX design solutions at Hexobyte!
      Transforming digital interactions with intuitive UX design.`,
    },
    devops: {
      title: "Devops | Hexobyte",
      description: `Transform Through Automation: Unlock Success with DevOps at Hexobyte. Effortlessly streamline your software development and deployment process with our expert DevOps services.`,
    },
    qa: {
      title: "Quality Engineering | Hexobyte",
      description: `Driving Excellence Through Quality Engineering at Hexobyte!
      Unlocking Quality Engineering Excellence: Elevating Software Performance and Reliability.`,
    },
    dataAndAi: {
      title: "Data And Artificial Intelligence | Hexobyte",
      description: `Empowering Insights, Enriching Intelligence.
      Unleash the power of data and AI for informed decisions and innovation at Hexobyte.`,
    },
    architecture: {
      title: "Architecture Services | Hexobyte",
      description: `Architecting Success: Tailored Solutions for Your Software. Crafting the Blueprint for Digital Excellence at Hexobyte`,
    },
  },
};
