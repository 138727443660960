// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKSelectBox from "components/MKSelect";
import { MenuItem } from "@mui/material";
import Container from "@mui/material/Container";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Card from "@mui/material/Card";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/contact_us.webp";
import RenderHelmet from "components/helmet/helmet";
import metaData from "components/helmet/meta-data/meta-data";

import React, { useState } from "react";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactReason: "",
    requirementDetails: "",
    timestamp: new Date().toISOString(),
  });

  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const isFormValid =
    formData.name &&
    isValidEmail(formData.email) &&
    formData.contactReason &&
    formData.requirementDetails;

  const [submissionStatus, setSubmissionStatus] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Send the form data to Google Apps Script
    fetch(
      "https://script.google.com/macros/s/AKfycbw6KllJ9BDCHKx_1GLMCEZuFGE7Iul-a0KIMTNmjr32HXHl7ZEPY5ClnhTVgGC4HwYknw/exec",
      {
        method: "POST",
        mode: "no-cors", // Important to avoid CORS errors
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(formData),
      }
    )
      .then(() => {
        setSubmissionStatus("Form submitted successfully!");
        setFormData({
          name: "",
          email: "",
          contactReason: "",
          requirementDetails: "",
        });

        setTimeout(() => {
          setSubmissionStatus("");
        }, 5000);
      })
      .catch((error) => console.error("Error submitting form", error));
  };

  return (
    <>
      <RenderHelmet metaData={metaData.contactUs} />
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Exploring Collaborative Opportunities for Growth
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              We&apos;re eager to connect and will reach out soon.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 0, md: "auto", lg: "auto" },
          mt: -8,
          mb: 4,
          width: {
            xs: "100%",
            md: "80%",
            lg: "60%",
          },
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 16 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Fill out the form
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Help us with the following details
              </MKTypography>
              <MKBox
                width="100%"
                component="form"
                method="post"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="Name"
                      name="name"
                      fullWidth
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      type="email"
                      name="email"
                      variant="standard"
                      label="Email"
                      fullWidth
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKSelectBox
                      label="You're contacting us for?"
                      name="contactReason"
                      variant="standard"
                      fullWidth
                      value={formData.contactReason}
                      onChange={(e) => setFormData({ ...formData, contactReason: e.target.value })}
                    >
                      <MenuItem value="Job">Job</MenuItem>
                      <MenuItem value="Building A Product">Building A Product</MenuItem>
                      <MenuItem value="Other Enquiry">Other Enquiry</MenuItem>
                    </MKSelectBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      name="requirementDetails"
                      label="What can we help you?"
                      placeholder="Describe your problem in at least 250 characters"
                      multiline
                      fullWidth
                      rows={6}
                      value={formData.requirementDetails}
                      onChange={(e) =>
                        setFormData({ ...formData, requirementDetails: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info" disabled={!isFormValid}>
                    Submit
                  </MKButton>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKTypography variant="body2" color="info" mt={2}>
                    {submissionStatus}
                  </MKTypography>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
