/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
// import ExampleCard from "pages/Presentation/components/ExampleCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Data
import data from "pages/Presentation/sections/data/servicesData";

function ServicesBlocks() {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>

      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ route, variant, color, icon, title, description, action }) => (
            <Grid item xs={12} lg={6} key={title} sx={{ display: "flex" }}>
              <Link to={route ?? "/"} style={{ display: "flex" }}>
                <FilledInfoCard
                  variant={variant}
                  color={color ?? "info"}
                  icon={icon}
                  title={title}
                  description={description}
                  action={action}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="Our Services"
            container
            sx={{ mb: 2 }}
          /> */}
          <MKTypography variant="h2" fontWeight="bold">
            Innovative Solutions for Your Digital Success
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Discover our comprehensive suite of services designed to propel your business forward in
            the digital age, from cutting-edge software development to strategic consultancy, all
            tailored to meet your unique needs and drive tangible results.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default ServicesBlocks;
