// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Hexobyte Solutions",
    image: logoCT,
    route: "/",
  },
  addresses: [
    {
      title: "Pune, India",
      address: (
        <MKTypography variant="button" fontWeight="regular">
          Tower 5, <br />
          WeWork World Trade Center,
          <br /> Kharadi,
          <br /> MIDC Knowledge Park, <br />
          Pune, <br />
          India, 411014
        </MKTypography>
      ),
    },
    // {
    //   title: "Nagpur, India",
    //   address: "104, Aman Flora, Katol Rd, Nagpur, India, 440013",
    // },
    // {
    //   title: "Dubai, United Arab Emirates",
    //   address: "606, Elite Business Bay, Dubai, United Arab Emirates",
    // },
  ],
  menus: [
    {
      name: "help & support",
      items: [
        { name: "Home", route: "/" },
        { name: "About us", route: "/about-us" },
        { name: "contact us", route: "/contact-us" },
      ],
    },
  ],
  services: [
    {
      name: "Our Services",
      items: [
        { name: "Web Development", route: "/our-services/web-development" },
        { name: "Mobile Development", route: "/our-services/mobile-development" },
        { name: "UX Design", route: "/our-services/ux-design" },
        { name: "DevOps Services", route: "/our-services/devops" },
        { name: "Quality Engineering", route: "/our-services/quality-engineering" },
        { name: "Data & Artificial Intelligence", route: "/our-services/data-ai" },
        { name: "Architecture Services", route: "/our-services/architecture" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. <br />
      Copyright &copy; <strong>{date}</strong> by{" "}
      <MKTypography
        component="a"
        href="https://hexobyte.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="bold"
      >
        Hexobyte Solutions.
      </MKTypography>
    </MKTypography>
  ),
};
