/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
// import ExampleCard from "pages/Presentation/components/ExampleCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Data
import data from "pages/our-services/data/services.data";

function ServicesBlocks({ page }) {
  const { title, description, items, headerTitle, headerDescription } = data[page];
  const RenderData = () => {
    return items?.length ? (
      <Grid container spacing={3} key={title}>
        <Grid item xs={12} lg={3}>
          <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
            <MKTypography variant="h3" fontWeight="bold" mb={1}>
              {title}
            </MKTypography>
            <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
              {description}
            </MKTypography>
          </MKBox>
        </Grid>

        <Grid item xs={12} lg={9}>
          <Grid container spacing={3}>
            {items.map(({ route, variant, color, icon, title, description }) => (
              <Grid item xs={12} lg={6} key={title} sx={{ display: "flex" }}>
                {route ? (
                  <Link to={route ?? "/"}>
                    <FilledInfoCard
                      variant={variant}
                      color={color ?? "info"}
                      icon={icon}
                      title={title}
                      description={description}
                    />
                  </Link>
                ) : (
                  <FilledInfoCard
                    variant={variant}
                    color={color ?? "info"}
                    icon={icon}
                    title={title}
                    description={description}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    ) : null;
  };

  return (
    <MKBox component="section" my={6} py={6}>
      {headerTitle && (
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={7}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
          >
            <MKTypography variant="h2" fontWeight="bold">
              {headerTitle}
            </MKTypography>
            <MKTypography variant="body1" color="text" sx={{ marginTop: "12px" }}>
              {headerDescription}
            </MKTypography>
          </Grid>
        </Container>
      )}
      <Container sx={{ mt: 6 }}>
        <RenderData />
      </Container>
    </MKBox>
  );
}

ServicesBlocks.propTypes = {
  page: PropTypes.string,
};

export default ServicesBlocks;
