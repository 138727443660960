// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/shapes/waves-white.svg";
import technologiesData from "./data/technologiesData";

function Download() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 12 }}>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        <MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          opacity={0.2}
        />
        <Container sx={{ position: "relative", zIndex: 2, py: 12 }}>
          <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography variant="h3" color="white">
              Ready to Enhance Your Application?
            </MKTypography>

            <MKTypography variant="body2" color="white" mb={6}>
              Your journey to a more robust and refined application begins here. Hexobyte is poised
              to unlock new potentials in efficiency and design for your project. With just a few
              clicks, you can begin to explore the breadth of features and tools that set Hexobyte
              apart from the rest.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <Grid container item xs={6} mx="auto">
          <MKBox textAlign="center">
            <MKTypography variant="h3" mt={6} mb={3}>
              Harness the Power of Diverse Technologies
            </MKTypography>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {technologiesData.map((data, index) => (
                <Grid item xs={4} lg={2} key={index}>
                  <Tooltip title={data.tooltipTitle}>
                    <MKBox
                      component={data?.href ? "a" : "div"}
                      opacity={0.8}
                      href={data.href ?? "/"}
                      target="_blank"
                      sx={{ cursor: "pointer" }}
                    >
                      <MKBox component="img" src={data.imageUrl} width="100%" />
                    </MKBox>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Download;
