// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BuiltByDevelopers() {
  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={10} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h4" color="white" fontWeight="bold">
            Elevating Software Excellence
          </MKTypography>
          <MKTypography variant="h1" color="white" mb={1}>
            Our Commitment to Clean Code Practices
          </MKTypography>
          <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
            At Hexobyte, we pride ourselves on adhering to the highest standards of software
            development, including rigorous adherence to SOLID principles, design patterns, and
            industry best practices. With a relentless focus on clean code and quality assurance, we
            ensure that every line of code we write reflects our dedication to excellence. Trust us
            to deliver software solutions that are not just functional, but robust, scalable, and
            maintainable, setting the foundation for your digital success.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
